@font-face {
  font-family: "Trim";
  src: url("https://static1.squarespace.com/static/62a0f19e512d0033ab7182d4/t/62a1023366596e30186edf96/1654719027502/Trim-Regular+%281%29.otf");
  font-display: swap;
}

@font-face {
  font-family: "Bokor";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bokor/v30/m8JcjfpeeaqTiS2afojrcQ.woff2)
    format("woff2");
  unicode-range: U+1780-17FF, U+200C, U+25CC;
}

@font-face {
  font-family: "Bokor";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bokor/v30/m8JcjfpeeaqTiS2Rfog.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  margin: 0;
  background: black;
}

a {
  text-decoration: none;
}

::-moz-selection {
  color: #a38831;
  background: #f9edc4;
}

::selection {
  color: #a38831;
  background: #f9edc4;
}

.unselectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  pointer-events: none;
}

.header {
  color: #fff;
  font-family: "Trim", sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 2rem;

  margin: 0;
}

.link {
  text-decoration: underline;

  color: #fff;
}

.link:hover {
  color: #ddd;
}

.button {
  color: #000;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;

  border: none;

  background: #fff;

  box-shadow: rgba(255, 255, 255, 0.25) 0px 13px 27px -5px,
    rgba(255, 255, 255, 0.3) 0px 8px 16px -8px;

  padding: 10px 24px;
}

.button:hover {
  cursor: pointer;
  background: #ddd;
}

@media screen and (min-width: 1944px) {
  .header {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 768px),
  screen and (max-width: 1943px) and (orientation: landscape) {
  .header {
    font-size: 64px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .header {
    font-size: 36px;
  }
}
